import classnames from "classnames";
import React from "react";

import { btnDisabled, button, primary, secondary } from "./style.module.scss";

interface Props {
    children: Node;
    onClick: () => void;
    disabled?: boolean;
    color?: unknown;
}

const Button = ({ children, disabled, onClick, color }: Props) => (
    <button
        onClick={onClick}
        className={classnames(
            button,
            !!color && color === "primary" ? primary : secondary,
            disabled && btnDisabled,
        )}
        disabled={disabled}
    >
        {children}
    </button>
);

export default Button;
