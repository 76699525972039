import { PunkImage } from "@components";
import { Paper, Typography } from "@mui/material";
import { Link } from "gatsby";
import React from "react";

import { card, id, information } from "./style.module.scss";

interface Offer {
    hasOffer: boolean;
    minValue: string;
    privateSale: string;
}

interface Bid {
    hasBid: boolean;
    bidder: string;
    value: string;
}

interface Props {
    punkId: string;
    wrapped: boolean;
    owner: string;
    offer: Offer;
    bid: Bid;
}

const Card = ({ punkId, offer, bid, wrapped, owner }: Props): Node => (
    <Link to={`/punks/${punkId}`}>
        <Paper elevation={3} className={card}>
            <Typography className={id}>#{punkId}</Typography>
            <PunkImage punkId={punkId} color={{ wrapped, bid, offer, owner }} />
            <div className={information}>
                {offer.hasOffer && offer.seller === owner && (
                    <Typography>
                        <span>For sale:</span>
                        <span>{parseFloat(offer.minValue).toFixed(2)} ETH</span>
                    </Typography>
                )}
                {bid.hasBid && (
                    <Typography>
                        <span>Bid:</span>
                        <span>{parseFloat(bid.value).toFixed(2)} ETH</span>
                    </Typography>
                )}
            </div>
        </Paper>
    </Link>
);

export default Card;
