import { Button } from "@components";
import { Typography } from "@mui/material";
import { ENSName } from "ethereum-ens-name";
import React, { Fragment } from "react";

import {
    btnLayout,
    informationLayout,
    noWallet,
    ownerTxt,
} from "./style.module.scss";

interface Props {
    currentAccount: string;
    provider: unknown;
    punk: unknown;
    wrapPunk: () => void;
    unwrapPunk: () => void;
    makeOffer: () => void;
    revokeOffer: () => void;
    acceptBid: () => void;
    buyPunk: () => void;
    makeBid: () => void;
    revokeBid: () => void;
}

const DetailOptions = ({
    currentAccount,
    provider,
    punk: { bid, offer, owner, wrapped },
    wrapPunk,
    makeOffer,
    revokeOffer,
    makeBid,
    revokeBid,
    acceptBid,
    buyPunk,
}: Props): Node => {
    // eslint-disable-next-line
    const getButtonConfig = () => {
        if (!currentAccount) {
            return (
                <Typography variant="h4" component="p" className={noWallet}>
                    Please connect a wallet to enable actions
                </Typography>
            );
        }

        if (owner === currentAccount) {
            return (
                <div className={btnLayout}>
                    {!wrapped && (
                        <Button color={"primary"} onClick={wrapPunk}>
                            Wrap
                        </Button>
                    )}
                    {wrapped && (
                        <Fragment>
                            {bid.hasBid && (
                                <Button onClick={acceptBid} color={"secondary"}>
                                    Accept Bid
                                </Button>
                            )}
                            <Button
                                color={"primary"}
                                onClick={() =>
                                    offer.hasOffer && offer.seller === owner
                                        ? revokeOffer()
                                        : makeOffer()
                                }
                            >
                                {offer.hasOffer && offer.seller === owner
                                    ? "Cancel Sale"
                                    : "Sell"}
                            </Button>
                        </Fragment>
                    )}
                </div>
            );
        }

        return (
            <div className={btnLayout}>
                <Button
                    onClick={() => {
                        if (
                            !bid.hasBid ||
                            (bid.hasBid && bid.bidder !== currentAccount)
                        ) {
                            return makeBid();
                        }

                        return revokeBid();
                    }}
                    color={"secondary"}
                >
                    {!bid.hasBid ||
                    (bid.hasBid && bid.bidder !== currentAccount)
                        ? "Make Bid"
                        : "Revoke Bid"}
                </Button>
                {offer.hasOffer && offer.seller === owner && (
                    <Button onClick={buyPunk} color={"primary"}>
                        Buy Punk
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div>
            {getButtonConfig()}
            <div className={informationLayout}>
                {offer.hasOffer && offer.seller === owner && (
                    <Typography variant="body2" component="p">
                        <span>Sell price:</span>
                        {parseFloat(offer.minValue).toFixed(2)} ETH
                    </Typography>
                )}
                {bid.hasBid && (
                    <Typography variant="body2" component="p">
                        <span>Current bid:</span>
                        {parseFloat(bid.value).toFixed(2)} ETH
                    </Typography>
                )}
                <Typography variant="body2" component="p" className={ownerTxt}>
                    This punk belongs to{" "}
                    <span>
                        <ENSName address={owner} provider={provider} />
                    </span>
                </Typography>
            </div>
        </div>
    );
};

export default DetailOptions;
