import React from "react";

import { button } from "./style.module.scss";

interface Props {
    label: string;
    dataButton: string;
    onClick: () => void;
}

const ConnectButton = ({ label, onClick, dataButton }: Props) => {
    return (
        <button data-button={dataButton} onClick={onClick} className={button}>
            <span>{label}</span>
        </button>
    );
};

export default ConnectButton;
