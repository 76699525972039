/* eslint-disable sonarjs/no-duplicate-string */

export default [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
        inputs: [{ internalType: "uint256[]", name: "ids", type: "uint256[]" }],
        name: "getAllBids",
        outputs: [
            {
                components: [
                    { internalType: "bool", name: "wrapped", type: "bool" },
                    { internalType: "address", name: "owner", type: "address" },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "hasBid",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "bidder",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "value",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct PunksMarket.Bid",
                        name: "bid",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "isForSale",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "seller",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "minValue",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "onlySellTo",
                                type: "address",
                            },
                        ],
                        internalType: "struct PunksMarket.Offer",
                        name: "offer",
                        type: "tuple",
                    },
                ],
                internalType: "struct PunksMarket.Punk[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256[]", name: "ids", type: "uint256[]" }],
        name: "getAllForSale",
        outputs: [
            {
                components: [
                    { internalType: "bool", name: "wrapped", type: "bool" },
                    { internalType: "address", name: "owner", type: "address" },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "hasBid",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "bidder",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "value",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct PunksMarket.Bid",
                        name: "bid",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "isForSale",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "seller",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "minValue",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "onlySellTo",
                                type: "address",
                            },
                        ],
                        internalType: "struct PunksMarket.Offer",
                        name: "offer",
                        type: "tuple",
                    },
                ],
                internalType: "struct PunksMarket.Punk[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256[]", name: "ids", type: "uint256[]" }],
        name: "getDetailsForIds",
        outputs: [
            {
                components: [
                    { internalType: "bool", name: "wrapped", type: "bool" },
                    { internalType: "address", name: "owner", type: "address" },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "hasBid",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "bidder",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "value",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct PunksMarket.Bid",
                        name: "bid",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "isForSale",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "seller",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "minValue",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "onlySellTo",
                                type: "address",
                            },
                        ],
                        internalType: "struct PunksMarket.Offer",
                        name: "offer",
                        type: "tuple",
                    },
                ],
                internalType: "struct PunksMarket.Punk[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    { internalType: "bool", name: "wrapped", type: "bool" },
                    { internalType: "address", name: "owner", type: "address" },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "hasBid",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "bidder",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "value",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct PunksMarket.Bid",
                        name: "bid",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "isForSale",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "seller",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "minValue",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "onlySellTo",
                                type: "address",
                            },
                        ],
                        internalType: "struct PunksMarket.Offer",
                        name: "offer",
                        type: "tuple",
                    },
                ],
                internalType: "struct PunksMarket.Punk",
                name: "punk",
                type: "tuple",
            },
        ],
        name: "isForSale",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [],
        name: "mPContract",
        outputs: [
            { internalType: "contract PunksMarket", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
    },
];
