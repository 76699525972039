import { Header } from "@components";
import { useGetProgress } from "@hooks/recoil";
import { useWallet } from "@hooks/web3";
import { Typography } from "@mui/material";
import React from "react";
import BlockUi from "react-block-ui";

interface Props {
    children: Node;
}

const Layout = ({ children }: Props): Node => {
    const [inProgress, message] = useGetProgress();

    // hooks
    const { library, account } = useWallet();

    return (
        <BlockUi
            tag="div"
            blocking={inProgress}
            message={<Typography variant="body2">{message}</Typography>}
        >
            <Header address={account} provider={library} />
            {children}
        </BlockUi>
    );
};

export default Layout;
