import { Button } from "@components";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputAdornment,
    TextField,
} from "@mui/material";
import React, { Fragment, useState } from "react";

interface Props {
    type: string;
    open: boolean;
    handleClose: () => void;
    makeBid: (value: string) => void;
    makeOffer: (value: string) => void;
}

const InputModal = ({
    type,
    open,
    handleClose,
    makeBid,
    makeOffer,
}: Props): Node => {
    // state
    const [value, setValue] = useState<string>("");
    const [address, setAddress] = useState<string | boolean>(false);
    const [private, setPrivate] = useState<boolean>(false);
    const [numberError, setNumberError] = useState({
        error: false,
        message: "",
    });
    const [addressError, setAddressError] = useState({
        error: false,
        message: "",
    });

    const onClose = () => {
        setValue("");
        setAddress("");
        setPrivate(false);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {type === "Offer" ? "Enter selling price" : "Enter bid"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    type="number"
                    label={type === "Offer" ? "Selling price" : "Bid price"}
                    value={value}
                    error={numberError.error}
                    helperText={numberError.message}
                    onChange={event => {
                        if (Math.sign(event.target.value) === -1) {
                            return setNumberError({
                                error: true,
                                message: "You can't bid a negative number",
                            });
                        }

                        !!numberError.error &&
                            setNumberError({ error: false, message: "" });
                        return setValue(event.target.value);
                    }}
                    inputProps={{ min: "0", inputMode: "decimal" }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">ETH</InputAdornment>
                        ),
                    }}
                />
                {type === "Offer" && (
                    <Fragment>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={event =>
                                        setPrivate(event.target.checked)
                                    }
                                />
                            }
                            label="Private Sale"
                        />
                        {!!private && (
                            <TextField
                                variant="standard"
                                label={"Address"}
                                value={address || ""}
                                error={addressError.error}
                                helperText={
                                    addressError.message ||
                                    "DISCLAIMER: we are not responsible in any way for private sales to a wrong address"
                                }
                                onChange={event => {
                                    if (!event.target.value.startsWith("0x")) {
                                        setAddressError({
                                            error: true,
                                            message:
                                                "This address is not valid. It should start with '0x'.",
                                        });
                                        return setAddress(event.target.value);
                                    }

                                    !!addressError.error &&
                                        setAddressError({
                                            error: false,
                                            message: "",
                                        });
                                    return setAddress(event.target.value);
                                }}
                            />
                        )}
                    </Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color={"secondary"}>
                    Cancel
                </Button>
                <Button
                    disabled={
                        private
                            ? !value || !address || addressError.error
                            : !value
                    }
                    onClick={() => {
                        type === "Bid"
                            ? makeBid(value)
                            : makeOffer(value, address);
                        onClose();
                    }}
                    color={"primary"}
                >
                    {type === "Offer" ? "Sell" : "Make bid"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InputModal;
