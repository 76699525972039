import classnames from "classnames";
import React from "react";

import {
    bgBid,
    bgOffer,
    bgUnwrapped,
    bgWrapped,
    borderImg,
} from "./style.module.scss";

interface Props {
    punkId: string;
    color: unknown;
    border?: boolean;
}

const HASH_CODE = "QmXb1H9MvMvRDXmKfFvL4i9PYLo4pJKY86SL98HKQp7HBw";

const PunkImage = ({ punkId, color, border }: Props): Node => {
    const { wrapped, bid, offer, owner } = color;

    const getBackground = () => {
        if (!wrapped) return bgUnwrapped;

        if (offer.hasOffer && offer.seller === owner) {
            return bgOffer;
        }

        if (bid.hasBid) return bgBid;

        return bgWrapped;
    };

    const getImage = () => {
        // const imgUrls = [
        //     "ipfs.io",
        //     "cloudflare-ipfs.com",
        //     "gateway.pinata.cloud",
        // ];

        // const random = Math.floor(Math.random() * 3);
        // console.log("IMG", random, imgUrls[random]);

        return `https://cloudflare-ipfs.com/ipfs/${HASH_CODE}/${punkId}.svg`;
    };

    if (border) {
        return (
            <div className={borderImg}>
                <img src={getImage()} className={classnames(getBackground())} />
            </div>
        );
    }

    return <img src={getImage()} className={classnames(getBackground())} />;
};

export default PunkImage;
