import {
    useMarketPunks,
    useOwnedPunks,
    useSetAlert,
    useTotalVolume,
} from "@hooks/recoil";
import { mapPunk } from "@utils";
import { serializeError } from "eth-rpc-errors";
import { utils } from "ethers";
import { useCallback, useEffect, useState } from "react";

import { useContract } from "./web3";
import { helperAbi, marketAbi } from "./web3/abi";

const { formatEther, formatUnits } = utils;

export default (provider: unknown, account?: string, manual: boolean) => {
    // state
    const [init, setInit] = useState<boolean>(true);

    // hooks
    const setAlert = useSetAlert();
    const [totalVolume, setTotalVolume] = useTotalVolume();
    const [marketPunks, setMarketPunks] = useMarketPunks();
    const [ownedPunks, setOwnedPunks] = useOwnedPunks();

    // contracts
    const { contract: marketContract, getContract: getMarketContract } =
        useContract(process.env.GATSBY_MARKET_ADDRESS, marketAbi);
    const { contract: helperContract, getContract: getHelperContract } =
        useContract(process.env.GATSBY_HELPER_ADDRESS, helperAbi);

    // internal functions
    const filterIds = (ids: Array[], filterArray: Array[]) =>
        ids.filter(
            id => !filterArray.find(({ punkId }) => punkId === id) && id,
        );

    const displayError = useCallback(
        (error: unknown) => {
            const message = serializeError(error)?.data?.originalError?.reason;
            setAlert({ severity: "warning", message });
            return undefined;
        },
        [setAlert],
    );

    const handleGetPunks = useCallback(
        async (method: string, ids: number[]) =>
            await helperContract[method](ids).then(
                response => response,
                displayError,
            ),
        [helperContract, displayError],
    );

    const updateState = useCallback(
        (state: unknown, stateName: string) => {
            if (account) {
                return setOwnedPunks(prevState => ({
                    ...prevState,
                    [stateName]: state,
                }));
            }

            setMarketPunks(prevState => ({ ...prevState, [stateName]: state }));
        },
        [account, setMarketPunks, setOwnedPunks],
    );

    const getPunkDetails = useCallback(
        async (ids: Array[]) => {
            let filtered = ids
                .map(id => formatUnits(id, 0))
                .filter(id => id !== "11111")
                .map(id => parseInt(id));

            // Punks for sale
            await handleGetPunks("getAllForSale", filtered).then(response => {
                response = response
                    .map(punk => mapPunk(punk))
                    .sort((a, b) => a.offer.minValue - b.offer.minValue);
                filtered = filterIds(filtered, response);
                updateState(response, "sales");
            });

            // Punks with bid
            await handleGetPunks("getAllBids", filtered).then(response => {
                response = response
                    .map(punk => mapPunk(punk))
                    .sort((a, b) => b.bid.value - a.bid.value);
                filtered = filterIds(filtered, response);
                updateState(response, "bids");
            });

            // Punks wrapped
            await handleGetPunks("getDetailsForIds", filtered).then(
                response => {
                    response = response
                        .map((punk, index) => mapPunk(punk, filtered[index]))
                        .sort((a, b) => a.punkId - b.punkId);
                    filtered = filterIds(filtered, response);
                    updateState(response, "wrapped");
                },
            );
        },
        [handleGetPunks, updateState],
    );

    // Handle contract calls
    const getPunkIds = useCallback(async () => {
        if (account) {
            const unwrapped = await marketContract.getPunksForAddress(account);
            const wrapped = await marketContract.getWrappedPunksForAddress(
                account,
            );
            return getPunkDetails(unwrapped.concat(wrapped));
        }

        const allIds = await marketContract.getAllWrappedPunks();
        return getPunkDetails(allIds);
    }, [marketContract, getPunkDetails, account]);

    const getVolume = useCallback(async () => {
        const totalVolume = await marketContract.totalVolume();
        setTotalVolume(formatEther(totalVolume));
    }, [marketContract, setTotalVolume]);

    // Initialize Etherscan contracts
    useEffect(() => {
        if (!manual && !!provider && !marketContract && !helperContract) {
            getMarketContract(provider);
            getHelperContract(provider);
        }

        if (marketContract && helperContract && init) {
            getPunkIds();
            getVolume();
            setInit(false);
        }
    }, [
        manual,
        provider,
        marketContract,
        helperContract,
        getMarketContract,
        getHelperContract,
        getPunkIds,
        getVolume,
        init,
    ]);

    return {
        totalVolume,
        marketPunks,
        ownedPunks,
        // refetch: () => {
        //     console.log("REFETCHING");
        // },
    };
};
