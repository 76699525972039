import { DetailGrid, DetailOptions, InputModal, PunkImage } from "@components";
import { usePermission, useSetAlert, useSetProgress } from "@hooks/recoil";
import { useContract, useWallet } from "@hooks/web3";
import { marketAbi, punkAbi, wrapperAbi } from "@hooks/web3/abi";
import { Typography } from "@mui/material";
import { getPunkDetails } from "@utils";
import { serializeError } from "eth-rpc-errors";
import { providers, utils } from "ethers";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import Helmet from "react-helmet";

const { parseEther } = utils;

const PunkDetail = ({ punkId }: unknown): Node => {
    // infura provider
    const openProvider = useMemo(
        () =>
            new providers.AlchemyProvider(
                process.env.GATSBY_ETH_MAINNET === "true" ? null : "goerli",
                process.env.GATSBY_ALCHEMY_PROVIDER_ID,
            ),
        [],
    );

    // state
    const [punk, setPunk] = useState(undefined);
    const [modal, setModal] = useState({ open: false, type: undefined });

    // hooks
    const { signer, account } = useWallet();
    const [permission, setPermission] = usePermission();
    const setTxInProgress = useSetProgress();
    const setAlert = useSetAlert();
    const {
        contract: punkContract,
        getContract: getPunkContract,
        execContract: execPunkContract,
    } = useContract(process.env.GATSBY_PUNK_ADDRESS, punkAbi);
    const {
        contract: wrapperContract,
        getContract: getWrapperContract,
        execContract: execWrapperContract,
    } = useContract(process.env.GATSBY_WRAPPER_ADDRESS, wrapperAbi);
    const {
        contract: marketContract,
        getContract: getMarketContract,
        execContract: execMarketContract,
    } = useContract(process.env.GATSBY_MARKET_ADDRESS, marketAbi);

    const getPunk = useCallback(async () => {
        const punk = await getPunkDetails(punkId, marketContract);
        setPunk(punk);
    }, [punkId, marketContract]);

    const checkPermission = useCallback(async () => {
        const permission = await wrapperContract.isApprovedForAll(
            account,
            process.env.GATSBY_MARKET_ADDRESS,
        );
        setPermission(permission);
    }, [account, wrapperContract, setPermission]);

    useEffect(() => {
        // Get details of punkId
        if (!punk) {
            getMarketContract(signer ?? openProvider);
            !!marketContract && getPunk();
        }

        // Get writable contract-connections
        if (signer && !wrapperContract && !punkContract) {
            getPunkContract(signer);
            getWrapperContract(signer);
            getMarketContract(signer);
        }

        // Check contract permissions
        if (wrapperContract && typeof permission === "undefined") {
            checkPermission();
        }
    }, [
        punk,
        getPunk,
        account,
        signer,
        openProvider,
        punkContract,
        wrapperContract,
        marketContract,
        getPunkContract,
        getWrapperContract,
        getMarketContract,
        permission,
        checkPermission,
    ]);

    const handleGetPermission = async () =>
        await execWrapperContract(
            inProgress =>
                setTxInProgress([
                    inProgress,
                    inProgress
                        ? "Managing contract permissions, please wait... (Step 1 of 2) "
                        : "",
                ]),
            "setApprovalForAll",
            [process.env.GATSBY_MARKET_ADDRESS, true],
        ).then(
            () => setPermission(true),
            error => {
                const message =
                    serializeError(error)?.data?.originalError?.reason;
                setAlert({ severity: "warning", message });
            },
        );

    const handleRefetch = async () => {
        const punk = await getPunkDetails(punkId, marketContract);
        setPunk(punk);
    };

    return (
        <Fragment>
            <Helmet>
                <title>#{punkId} | CryptoPunks V1</title>
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="CryptoPunks V1" />
                <meta
                    property="og:url"
                    content={`https://www.v1punks.io/punks/${punkId}`}
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:image"
                    content={`https://ipfs.io/ipfs/QmbuBFTZe5ygELZhRtQkpM3NW8nVXxRUNK9W2XFbAXtPLV/${punkId}.png`}
                />
                <meta
                    property="og:image"
                    content={`https://ipfs.io/ipfs/QmbuBFTZe5ygELZhRtQkpM3NW8nVXxRUNK9W2XFbAXtPLV/${punkId}.png`}
                />
                <meta
                    property="og:title"
                    content={`CryptoPunks V1 #${punkId}`}
                />
                <meta
                    property="og:description"
                    content="A wrapped CryptoPunk from the original exploited V1 contract of the wellknown CryptoPunks NFT set released by LarvaLabs in 2017. Buyer beware - if you don't understand the preceding sentence, this is not the CryptoPunk you're looking for."
                />
            </Helmet>
            <DetailGrid>
                {!punk ? (
                    <Typography variant="h4" component="p">
                        Loading...
                    </Typography>
                ) : (
                    <Fragment>
                        <PunkImage
                            punkId={punkId}
                            color={{
                                wrapped: punk.wrapped,
                                bid: punk.bid,
                                offer: punk.offer,
                                owner: punk.owner,
                            }}
                            border
                        />
                        <DetailOptions
                            currentAccount={account}
                            provider={openProvider}
                            punk={punk}
                            wrapPunk={async () => {
                                const origPunk =
                                    await punkContract.punksOfferedForSale(
                                        punkId,
                                    );
                                if (
                                    !origPunk ||
                                    !origPunk?.isForSale ||
                                    origPunk?.onlySellTo !==
                                        process.env.GATSBY_WRAPPER_ADDRESS
                                ) {
                                    await execPunkContract(
                                        inProgress =>
                                            setTxInProgress([
                                                inProgress,
                                                inProgress
                                                    ? "Moving punk to wrapper contract, please wait... (Step 1 of 2)"
                                                    : "",
                                            ]),
                                        "offerPunkForSaleToAddress",
                                        [
                                            punk.punkId,
                                            0,
                                            process.env.GATSBY_WRAPPER_ADDRESS,
                                        ],
                                    );
                                }
                                await execWrapperContract(
                                    inProgress =>
                                        setTxInProgress([
                                            inProgress,
                                            inProgress
                                                ? "Wrapping your punk, please wait... (Step 2 of 2)"
                                                : "",
                                        ]),
                                    "wrap",
                                    [punk.punkId],
                                    parseEther("0"),
                                    handleRefetch,
                                );
                            }}
                            makeOffer={() =>
                                setModal({
                                    open: true,
                                    type: "Offer",
                                })
                            }
                            revokeOffer={() =>
                                execMarketContract(
                                    inProgress =>
                                        setTxInProgress([
                                            inProgress,
                                            inProgress
                                                ? "Revoking offer, please wait..."
                                                : "",
                                        ]),
                                    "punkNoLongerForSale",
                                    [punk.punkId],
                                    undefined,
                                    handleRefetch,
                                )
                            }
                            makeBid={() =>
                                setModal({ open: true, type: "Bid" })
                            }
                            revokeBid={() =>
                                execMarketContract(
                                    inProgress =>
                                        setTxInProgress([
                                            inProgress,
                                            inProgress
                                                ? "Revoking bid, please wait..."
                                                : "",
                                        ]),
                                    "withdrawBidForPunk",
                                    [punk.punkId],
                                    undefined,
                                    handleRefetch,
                                )
                            }
                            acceptBid={async () => {
                                if (!permission) {
                                    await handleGetPermission();
                                }
                                execMarketContract(
                                    inProgress =>
                                        setTxInProgress([
                                            inProgress,
                                            inProgress
                                                ? `Accepting bid, please wait...${
                                                      !permission &&
                                                      " (Step 2 of 2)"
                                                  }`
                                                : "",
                                        ]),
                                    "acceptBidForPunk",
                                    [punk.punkId, parseEther(punk.bid.value)],
                                    undefined,
                                    handleRefetch,
                                );
                            }}
                            buyPunk={() =>
                                execMarketContract(
                                    inProgress =>
                                        setTxInProgress([
                                            inProgress,
                                            inProgress
                                                ? "Buying punk, please wait..."
                                                : "",
                                        ]),
                                    "buyPunk",
                                    [punk.punkId],
                                    parseEther(punk?.offer?.minValue),
                                    handleRefetch,
                                )
                            }
                        />
                    </Fragment>
                )}
            </DetailGrid>
            <InputModal
                type={modal.type}
                open={modal.open}
                handleClose={() => setModal({ open: false, type: undefined })}
                makeBid={value =>
                    execMarketContract(
                        inProgress =>
                            setTxInProgress([
                                inProgress,
                                inProgress
                                    ? "Making your bid, please wait..."
                                    : "",
                            ]),
                        "enterBidForPunk",
                        [punk.punkId],
                        parseEther(value),
                        handleRefetch,
                    )
                }
                makeOffer={async (value, private) => {
                    if (!permission) {
                        await handleGetPermission();
                    }
                    const args = [punk.punkId, parseEther(value)];
                    !!private && args.push(private); // add address if private sale
                    execMarketContract(
                        inProgress =>
                            setTxInProgress([
                                inProgress,
                                inProgress
                                    ? `Entering your selling price, please wait...${
                                          !permission && " (Step 2 of 2)"
                                      }`
                                    : "",
                            ]),
                        private // switch between functions if private sale
                            ? "offerPunkForSaleToAddress"
                            : "offerPunkForSale",
                        args,
                        undefined,
                        handleRefetch,
                    );
                }}
            />
        </Fragment>
    );
};

export default PunkDetail;
