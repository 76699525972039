import { ConnectButton } from "@components";
import { useWallet } from "@hooks/web3";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import { buttons, metamask, modal, walletconnect } from "./style.module.scss";

const WalletModal = () => {
    const [open, setOpen] = useState(false);
    const { connect, signer } = useWallet();

    return (
        <div>
            <ConnectButton
                label={signer ? "Disconnect" : "Connect"}
                onClick={() => (signer ? connect() : setOpen(true))}
                dataButton={signer ? "connect-on" : "connect-off"}
            />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={modal}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Choose a wallet provider
                    </Typography>
                    <div className={buttons}>
                        <div
                            data-trigger="walletconnect"
                            className={walletconnect}
                        >
                            <ConnectButton
                                label={"WalletConnect"}
                                onClick={() =>
                                    connect("walletConnect", () =>
                                        setOpen(false),
                                    )
                                }
                            />
                        </div>
                        <div data-trigger="metamask" className={metamask}>
                            <ConnectButton
                                label={"MetaMask"}
                                onClick={() =>
                                    connect("metamask", () => setOpen(false))
                                }
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default WalletModal;
