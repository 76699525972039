import { useSetAlert } from "@hooks/recoil";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";

import wallets from "./utils/wallets";

export default () => {
    const setAlert = useSetAlert();
    const [init, setInit] = useState<boolean>(true);
    const [signer, setSigner] = useState(undefined);
    const { activate, deactivate, active, library, chainId, account } =
        useWeb3React();

    // Connect to a wallet of choice
    const connect = useCallback(
        async (type?, handleClose?) => {
            try {
                if (active) {
                    window.localStorage.removeItem("CACHE_CONNECTION");
                    return await deactivate();
                }

                await activate(wallets[type.toLowerCase()]).then(handleClose);
            } catch {
                setAlert({
                    severity: "error",
                    message:
                        "Something went wrong while connecting your wallet",
                });
            }
        },
        [active, deactivate, activate, setAlert],
    );

    // Check what Ethereum network people are trying to connect with
    const checkEthNetwork = useCallback(() => {
        // Make sure people get connected to the Ethereum Mainnet in production
        if (process.env.GATSBY_ETH_MAINNET == "true" && chainId !== 1) {
            setAlert({
                severity: "warning",
                message: "You need to be on the Ethereum Mainnet",
            });
            return deactivate();
        }

        // Make sure people get connected to the Goerli Test Network in development
        if (process.env.GATSBY_ETH_MAINNET == "false" && chainId !== 5) {
            setAlert({
                severity: "warning",
                message: "You need to be on the Goerli Test Network",
            });
            return deactivate();
        }
    }, [chainId, setAlert, deactivate]);

    // Handle mainnet checks and signer on connection with a decentralized wallet
    useEffect(() => {
        if (active) {
            checkEthNetwork();

            // Cache wallet connection in local storage
            if (!window.localStorage.getItem("CACHE_CONNECTION")) {
                window.localStorage.setItem(
                    "CACHE_CONNECTION",
                    library?.connection.url,
                );
            }

            // Get and set an authorized signer for transactions
            !signer && setSigner(library.getSigner());
        }

        if (!active) {
            setSigner(undefined);

            // Connect immediately to wallet if stored in cache
            if (init && window.localStorage.getItem("CACHE_CONNECTION")) {
                const walletConnectFix = window.localStorage
                    .getItem("CACHE_CONNECTION")
                    .replace("eip-1193:", "walletconnect");
                connect(walletConnectFix);
                setInit(false);
            }
        }
    }, [active, checkEthNetwork, signer, library, init, connect]);

    return { connect, signer, library, account };
};
