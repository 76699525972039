import { createTheme } from "@mui/material/styles";

export default createTheme({
    typography: {
        fontFamily: "VT323, monospace",
    },
    palette: {
        primary: {
            main: "#01E100",
        },
        secondary: {
            main: "#b0e3b0",
        },
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontFamily: "Roboto, monospace",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: "14px",
                    "@media (min-width: 768px)": {
                        fontSize: "16px",
                    },
                },
                body2: {
                    color: "white",
                    fontSize: "1.2em",
                    lineHeight: "1.5",
                },
                overline: {
                    lineHeight: "1",
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#01E100",
                    backdropFilter: "blur(5px)",
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: "64px",
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: 0,
                    maxWidth: "90vw",
                    display: "flex",
                    alignItems: "center",

                    "@media (min-width: 600px)": {
                        padding: 0,
                    },

                    "@media (min-width: 1200px)": {
                        maxWidth: "90vw",
                    },

                    "@media (min-width: 1440px)": {
                        maxWidth: "88em",
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgb(0 0 0 / 75%)",
                    backdropFilter: "blur(.5em)",
                    width: "90%",

                    "@media (min-width: 640px)": {
                        width: "60%",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation3: {
                    backgroundColor: "black",
                    color: "#01E100",
                    borderRadius: "0",
                    border: ".2em solid rgb(1 225 0 / 15%)",
                    transition: "border-color 250ms ease-in-out",

                    "&:hover": {
                        borderColor: "rgb(1 225 0 / 100%)",
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: "white",

                    "&.Mui-selected": {
                        color: "#01E100",
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                container: {
                    backdropFilter: "blur(.25em)",
                },
                paper: {
                    width: "18em",
                    height: "25em",
                    margin: 0,
                    padding: "2em",
                    borderRadius: 0,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: "2em",
                    padding: 0,
                    marginBottom: ".5em",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    display: "flex",
                    flexFlow: "column",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 0,
                    flex: "none",
                    alignItems: "unset",
                    justifyContent: "unset",
                    display: "grid",
                    columnGap: "1em",
                    marginTop: "1.5em",

                    "> *": {
                        gridRow: 1,
                    },
                },
            },
        },
        // MuiButtonGroup: {
        //     styleOverrides: {
        //         root: {
        //             alignItems: "center",
        //         },
        //     },
        // },
        // MuiButton: {
        //     styleOverrides: {
        //         containedPrimary: {
        //             backgroundColor: "white",

        //             "&:hover": {
        //                 backgroundColor: "white",
        //             },

        //             "&.Mui-disabled": {
        //                 color: "white",
        //                 backgroundColor: "rgb(0 0 0 / 50%)",
        //             },
        //         },
        //     },
        // },
    },
});
