import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const chainId = process.env.GATSBY_ETH_MAINNET == "true" ? 1 : 5;
const chainName =
    process.env.GATSBY_ETH_MAINNET == "true" ? "mainnet" : "goerli";
const walletconnect = new WalletConnectConnector({
    rpc: {
        [chainId]: `https://eth-${chainName}.g.alchemy.com/v2/${process.env.GATSBY_ALCHEMY_PROVIDER_ID}`,
    },
    chainId,
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
});

const metamask = new InjectedConnector({ supportedChainIds: [1, 4] });

export default {
    metamask,
    walletconnect,
};
