import { CardGrid } from "@components";
import { useGetPunks } from "@hooks";
import { useWallet } from "@hooks/web3";
import { providers } from "ethers";
import React, { Fragment } from "react";
import Helmet from "react-helmet";

const Marketplace = () => {
    // alchemy provider
    const openProvider = new providers.AlchemyProvider(
        process.env.GATSBY_ETH_MAINNET === "true" ? null : "goerli",
        process.env.GATSBY_ALCHEMY_PROVIDER_ID,
    );

    // hooks
    const { signer } = useWallet();
    const { totalVolume, marketPunks } = useGetPunks(signer ?? openProvider);

    return (
        <Fragment>
            <Helmet>
                <title>Home | CryptoPunks V1</title>
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="CryptoPunks V1" />
                <meta property="og:url" content={`https://www.v1punks.io`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:image"
                    content="https://ipfs.io/ipfs/QmbuBFTZe5ygELZhRtQkpM3NW8nVXxRUNK9W2XFbAXtPLV/2619.png"
                />
                <meta
                    property="og:image"
                    content="https://ipfs.io/ipfs/QmbuBFTZe5ygELZhRtQkpM3NW8nVXxRUNK9W2XFbAXtPLV/2619.png"
                />
                <meta property="og:title" content={`Home | CryptoPunks V1`} />
                <meta
                    property="og:description"
                    content="The original exploited V1 contract of the wellknown CryptoPunks NFT set released by LarvaLabs in 2017. Buyer beware - if you don't understand the preceding sentence, this is not the CryptoPunk you're looking for."
                />
            </Helmet>
            <CardGrid
                sales={marketPunks.sales}
                bids={marketPunks.bids}
                wrapped={marketPunks.wrapped}
                totalVolume={totalVolume}
            />
        </Fragment>
    );
};

export default Marketplace;
