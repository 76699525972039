import { CardGrid } from "@components";
import { useGetPunks } from "@hooks";
import { useWallet } from "@hooks/web3";
import { Typography } from "@mui/material";
import React, { Fragment } from "react";
import Div100vh from "react-div-100vh";
import Helmet from "react-helmet";

const Profile = () => {
    // hooks
    const { signer, account } = useWallet();
    const { ownedPunks } = useGetPunks(signer, account);

    const helmet = (
        <Helmet>
            <title>Profile | CryptoPunks V1</title>
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="CryptoPunks V1" />
            <meta
                property="og:url"
                content={`https://www.v1punks.io/profile`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:image"
                content="https://ipfs.io/ipfs/QmbuBFTZe5ygELZhRtQkpM3NW8nVXxRUNK9W2XFbAXtPLV/2619.png"
            />
            <meta
                property="og:image"
                content="https://ipfs.io/ipfs/QmbuBFTZe5ygELZhRtQkpM3NW8nVXxRUNK9W2XFbAXtPLV/2619.png"
            />
            <meta property="og:title" content={`Profile | CryptoPunks V1`} />
            <meta
                property="og:description"
                content="The original exploited V1 contract of the wellknown CryptoPunks NFT set released by LarvaLabs in 2017. Buyer beware - if you don't understand the preceding sentence, this is not the CryptoPunk you're looking for."
            />
        </Helmet>
    );

    if (!signer || !account) {
        return (
            <Fragment>
                {helmet}
                <Div100vh
                    style={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h4" component="p">
                        Please connect to a wallet
                    </Typography>
                </Div100vh>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {helmet}
            <CardGrid
                sales={ownedPunks.sales}
                bids={ownedPunks.bids}
                wrapped={ownedPunks.wrapped}
            />
        </Fragment>
    );
};

export default Profile;
