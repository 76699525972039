import { Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";

import { container } from "./style.module.scss";

const DEADLINE = moment.utc("2022-01-24 17:00:00");

const ComingSoon = () => {
    // state
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const interval = setInterval(
            () =>
                setCountdown(
                    moment.duration(DEADLINE.diff(moment.utc()))._data,
                ),
            1000,
        );

        return () => {
            clearInterval(interval);
        };
    });

    return (
        <Div100vh className={container}>
            <Typography variant="h2" component="h2">
                Coming Soon
            </Typography>
            <div>
                <Typography variant="h4" component="p">
                    Days
                </Typography>
                <Typography variant="h4" component="p">
                    Hours
                </Typography>
                <Typography variant="h4" component="p">
                    Minutes
                </Typography>
                <Typography variant="h4" component="p">
                    Seconds
                </Typography>
                <Typography variant="h4" component="p">
                    {countdown.days}
                </Typography>
                <Typography variant="h4" component="p">
                    {countdown.hours}
                </Typography>
                <Typography variant="h4" component="p">
                    {countdown.minutes}
                </Typography>
                <Typography variant="h4" component="p">
                    {countdown.seconds}
                </Typography>
            </div>
        </Div100vh>
    );
};

export default ComingSoon;
