import { atom, useRecoilState } from "recoil";

// Total volume of punks
const totalVolumeState = atom({ key: "totalVolumeState", default: undefined });
export const useTotalVolume = () => useRecoilState(totalVolumeState);

// Market punks
const marketPunksState = atom({
    key: "marketPunksState",
    default: { sales: undefined, bids: undefined, wrapped: undefined },
});
export const useMarketPunks = () => useRecoilState(marketPunksState);

// Owned punks
const ownedPunksState = atom({
    key: "ownedPunksState",
    default: { sales: undefined, bids: undefined, wrapped: undefined },
});
export const useOwnedPunks = () => useRecoilState(ownedPunksState);
