import { Typography } from "@mui/material";
import React from "react";

import { container } from "./style.module.scss";

const AboutUs = () => (
    <div className={container}>
        <Typography variant="h1" component="h2">
            A little story
        </Typography>
        <Typography>
            Launched in June 2017 by Larva Labs, Cryptopunks were originally
            free for anyone with an Ethereum wallet to claim (+ gas). The Punks
            were a huge hit at launch and all of them were claimed incredibly
            fast. In the original Cryptopunks smart contract, there was a code
            error that caused Ethereum to be allocated to the buyer instead of
            the seller when a purchase was made. In other words, a buyer was
            immediately refunded for their purchase and was able to keep the
            Punk.
        </Typography>
        <Typography>
            Not long after the bug was found a newer version of the Punks
            contract (Version 2, V2) was released and Punks were airdropped to
            this new contract. V1 Punk owners are now able to wrap their Punks
            into an ERC-721 contract (another kind of standardized smart
            contract) and patch over the bug. This recovery of the original
            Punks smart contract is a community led and rapidly growing
            phenomenon consisting of original Punk claimant&apos;s, NFT
            historians, digital archeologists and extremely talented developers.
            There is no clear leader and all important decisions are voted on by
            community members.
        </Typography>
        <Typography>
            V1 Punks exist just as V2s do. This cannot be changed or undone
            since the V1 Punks are a permanent part of the blockchain. The new
            wrappers impart a slight change to the image in the form of a new
            background While this is a very modest change in the image, the core
            Punk remains the same.
        </Typography>
        <Typography>
            While the V1 Punks still exist in their original form, so does the
            existing bug in the V1 contract so NEVER put an unwrapped V1 on sale
            as you will most likely lose it. V1 Punks are not a derivative but
            are in fact the &ldquo;original/classic&rdquo; Cryptopunks. This is
            verified on the Ethereum blockchain and is immutable.
        </Typography>
    </div>
);

export default AboutUs;
