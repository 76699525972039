import { WalletModal } from "@components";
import { useWindow } from "@hooks/utils";
import { discord, logo, twitter } from "@images/icons";
// import logo from "@images/logo-black.png";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
    AppBar,
    Container,
    Drawer,
    SvgIcon,
    Toolbar,
    Typography,
} from "@mui/material";
import { AddressDisplayEnum, ENSName } from "ethereum-ens-name";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { Fragment, useState } from "react";

import {
    ensName,
    header,
    internalLinks,
    navigation,
    socialLinks,
} from "./style.module.scss";

const NavLinks = ({ mobile, address, provider }: unknown): Node => (
    <div className={navigation}>
        <div className={internalLinks}>
            {!!mobile && (
                <AnchorLink to="/#hero" title="Home" stripHash>
                    <Typography variant="body1" component="span">
                        Home
                    </Typography>
                </AnchorLink>
            )}
            <AnchorLink to="/profile" title="Profile" stripHash>
                <Typography variant="body1" component="span">
                    Profile
                    {address && (
                        <span className={ensName}>
                            <ENSName
                                address={address}
                                provider={provider}
                                displayType={AddressDisplayEnum.FIRST4_LAST4}
                            />
                        </span>
                    )}
                </Typography>
            </AnchorLink>
            <AnchorLink to="/about" title="About" stripHash>
                <Typography variant="body1" component="span">
                    About
                </Typography>
            </AnchorLink>
        </div>
        <div className={socialLinks}>
            <a
                href="https://twitter.com/v1punks"
                target="_blank"
                rel="noreferrer noopener"
            >
                <SvgIcon component={twitter} viewBox="0 0 248 204" />
            </a>
            <a
                href="https://discord.gg/R4n5vq7qwm"
                target="_blank"
                rel="noreferrer noopener"
            >
                <SvgIcon component={discord} viewBox="0 0 71 55" />
            </a>
        </div>
    </div>
);

const Navigation = ({ address, provider }: unknown): Node => {
    // state
    const [open, setOpen] = useState<boolean>(false);

    // hooks
    const { width } = useWindow();

    if (width === undefined) {
        return null;
    }

    if (width < 768) {
        return (
            <Fragment>
                <MenuIcon onClick={() => setOpen(true)} />
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={() => setOpen(false)}
                    onClick={() => setOpen(false)}
                >
                    <NavLinks mobile address={address} provider={provider} />
                </Drawer>
            </Fragment>
        );
    }

    return (
        <div className="navLinks">
            <NavLinks address={address} provider={provider} />
        </div>
    );
};

const Header = ({ address, provider }: unknown): Node => (
    <AppBar position="fixed">
        <Container>
            <Toolbar disableGutters className={header}>
                <AnchorLink to="/#hero" title="Home" stripHash>
                    <SvgIcon component={logo} viewBox="0 0 677 122" />
                </AnchorLink>
                <Navigation address={address} provider={provider} />
                <WalletModal />
            </Toolbar>
        </Container>
    </AppBar>
);

export default Header;
