import { utils } from "ethers";

const { formatEther, formatUnits } = utils;

export const mapPunk = (punk: string, id?: number) => {
    const offer = punk["offer"];
    const bid = punk["bid"];
    const punkId =
        parseInt(formatUnits(offer["punkIndex"], 0)) !== 0
            ? parseInt(formatUnits(offer["punkIndex"], 0))
            : parseInt(formatUnits(bid["punkIndex"], 0));

    return {
        punkId: id ?? punkId,
        wrapped: punk["wrapped"],
        owner: punk["owner"],
        offer: {
            hasOffer: offer["isForSale"],
            minValue: formatEther(offer["minValue"]),
            privateSale: offer["onlySellTo"],
            seller: offer["seller"],
        },
        bid: {
            hasBid: bid["hasBid"],
            bidder: bid["bidder"],
            value: formatEther(bid["value"]),
        },
    };
};

export const getPunkDetails = async (punkId: string, contract: unknown) => {
    const details = await contract.getPunksDetails(punkId);
    const offer = details["offer"];
    const bid = details["bid"];

    return {
        punkId,
        wrapped: details[0],
        owner: details[1],
        offer: {
            hasOffer: offer["isForSale"],
            minValue: formatEther(offer["minValue"]),
            privateSale: offer["onlySellTo"],
            seller: offer["seller"],
        },
        bid: {
            hasBid: bid["hasBid"],
            bidder: bid["bidder"],
            value: formatEther(bid["value"]),
        },
    };
};
