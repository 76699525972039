export default [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "account",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "AdminApproved",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "account",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "AdminRevoked",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "Paused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "punkIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "fromAddress",
                type: "address",
            },
        ],
        name: "PunkBidEntered",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "punkIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "fromAddress",
                type: "address",
            },
        ],
        name: "PunkBidWithdrawn",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "punkIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "fromAddress",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "toAddress",
                type: "address",
            },
        ],
        name: "PunkBought",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "punkIndex",
                type: "uint256",
            },
        ],
        name: "PunkNoLongerForSale",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "punkIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "minValue",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "toAddress",
                type: "address",
            },
        ],
        name: "PunkOffered",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "Unpaused",
        type: "event",
    },
    { stateMutability: "payable", type: "fallback" },
    {
        inputs: [],
        name: "TOTAL_PUNKS",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
            { internalType: "uint256", name: "minPrice", type: "uint256" },
        ],
        name: "acceptBidForPunk",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "admin", type: "address" }],
        name: "approveAdmin",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "buyPunk",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "enterBidForPunk",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [],
        name: "getAdmins",
        outputs: [
            { internalType: "address[]", name: "admins", type: "address[]" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getAllWrappedPunks",
        outputs: [{ internalType: "int256[]", name: "", type: "int256[]" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "getBid",
        outputs: [
            {
                components: [
                    { internalType: "bool", name: "hasBid", type: "bool" },
                    {
                        internalType: "uint256",
                        name: "punkIndex",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "bidder",
                        type: "address",
                    },
                    { internalType: "uint256", name: "value", type: "uint256" },
                ],
                internalType: "struct PunksMarket.Bid",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "getOffer",
        outputs: [
            {
                components: [
                    { internalType: "bool", name: "isForSale", type: "bool" },
                    {
                        internalType: "uint256",
                        name: "punkIndex",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "seller",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "minValue",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "onlySellTo",
                        type: "address",
                    },
                ],
                internalType: "struct PunksMarket.Offer",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
        name: "getPunksDetails",
        outputs: [
            {
                components: [
                    { internalType: "bool", name: "wrapped", type: "bool" },
                    { internalType: "address", name: "owner", type: "address" },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "hasBid",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "bidder",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "value",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct PunksMarket.Bid",
                        name: "bid",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "bool",
                                name: "isForSale",
                                type: "bool",
                            },
                            {
                                internalType: "uint256",
                                name: "punkIndex",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "seller",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "minValue",
                                type: "uint256",
                            },
                            {
                                internalType: "address",
                                name: "onlySellTo",
                                type: "address",
                            },
                        ],
                        internalType: "struct PunksMarket.Offer",
                        name: "offer",
                        type: "tuple",
                    },
                ],
                internalType: "struct PunksMarket.Punk",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "user", type: "address" }],
        name: "getPunksForAddress",
        outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "user", type: "address" }],
        name: "getWrappedPunksForAddress",
        outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "admin", type: "address" }],
        name: "isAdmin",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
            {
                internalType: "uint256",
                name: "minSalePriceInWei",
                type: "uint256",
            },
        ],
        name: "offerPunkForSale",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
            {
                internalType: "uint256",
                name: "minSalePriceInWei",
                type: "uint256",
            },
            { internalType: "address", name: "toAddress", type: "address" },
        ],
        name: "offerPunkForSaleToAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "pause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "paused",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "punkContract",
        outputs: [
            { internalType: "contract ICryptoPunk", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "punkNoLongerForSale",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "punksWrapperContract",
        outputs: [
            { internalType: "contract IERC721", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "returnBid",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "admin", type: "address" }],
        name: "revokeAdmin",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "revokeSale",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newpunksAddress",
                type: "address",
            },
        ],
        name: "setPunksWrapperContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "totalVolume",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "unpause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "punkIndex", type: "uint256" },
        ],
        name: "withdrawBidForPunk",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    { stateMutability: "payable", type: "receive" },
];
