import { Card } from "@components";
import { Container, Typography } from "@mui/material";
import React from "react";
import Div100vh from "react-div-100vh";

import { floorPrice, grid, layout } from "./style.module.scss";

interface Props {
    sales: Array[];
    bids: Array[];
    wrapped: Array[];
    totalVolume?: string;
}

const CardGrid = ({ sales, bids, wrapped, totalVolume }: Props): Node => {
    if (!sales && !bids && !wrapped) {
        return (
            <Div100vh
                style={{
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h4" component="p">
                    Loading...
                </Typography>
            </Div100vh>
        );
    }

    return (
        <Container className={layout}>
            {totalVolume && (
                <div className={floorPrice}>
                    <Typography variant={"h5"} component={"p"}>
                        <span>floor</span>
                        {sales[0].offer.minValue} ETH
                    </Typography>
                    <Typography variant={"h5"} component={"p"}>
                        <span>volume</span>
                        {totalVolume} ETH
                    </Typography>
                </div>
            )}
            <div className={grid}>
                {sales?.map(punk => (
                    <Card key={punk.punkId} {...punk} />
                ))}
                {bids?.map(punk => (
                    <Card key={punk.punkId} {...punk} />
                ))}
                {wrapped?.map(punk => (
                    <Card key={punk.punkId} {...punk} />
                ))}
            </div>
        </Container>
    );
};

export default CardGrid;
