import goBack from "@images/go-back.png";
import { navigate } from "gatsby";
import React from "react";
import Div100vh from "react-div-100vh";

import { goBackBtn, layout } from "./style.module.scss";

interface Props {
    children: Node;
}

const DetailGrid = ({ children }: Props): Node => (
    <Div100vh className={layout}>
        <img src={goBack} onClick={() => navigate("/")} className={goBackBtn} />
        {children}
    </Div100vh>
);

export default DetailGrid;
