import { getArguments, getOptions } from "@utils";
import { serializeError } from "eth-rpc-errors";
import { Contract } from "ethers";
import { useState } from "react";

import { useSetAlert } from "../recoil";

export default (address, abi) => {
    const setAlert = useSetAlert();
    const [contract, setContract] = useState(undefined);

    const execContract = async (
        setTxInProgress: (value: boolean) => void,
        method: string,
        args: unknown[],
        payableAmount?: unknown,
        refetchData?: () => void,
    ) => {
        setTxInProgress(true);
        const estimateGas = await contract.estimateGas[method](
            ...getArguments(args),
            getOptions(payableAmount),
        ).then(
            transaction => transaction,
            error => {
                const message =
                    serializeError(error)?.data?.originalError?.reason;
                setAlert({ severity: "warning", message });
            },
        );

        if (!estimateGas) {
            return setTxInProgress(false);
        }

        await contract[method](
            ...getArguments(args),
            getOptions(payableAmount, estimateGas),
        )
            .then(
                transaction =>
                    transaction.wait().then(
                        () =>
                            setAlert({
                                severity: "success",
                                message: "Transaction was successful",
                            }),
                        () =>
                            setAlert({
                                severity: "error",
                                message:
                                    "Something went wrong during transaction",
                            }),
                    ),
                () =>
                    setAlert({
                        severity: "warning",
                        message: "Transaction was canceled by the user",
                    }),
            )
            .then(() => {
                setTxInProgress(false);
                !!refetchData && refetchData();
            });
    };

    return {
        contract,
        getContract: async signer => {
            const contract = new Contract(address, abi, signer);
            setContract(contract);
        },
        execContract,
    };
};
